import React from "react";
import "./TermsOfService.css";

const TermsOfService = () => {
  return (
    <div dir="ltr" className="TermsOfService">
      <h1>Terms of Service for Bestabib</h1>
      <p>
        Welcome to Bestabib. By accessing and using our website, you agree
        to comply with the following Terms of Service. Please read these terms
        carefully before using our platform. If you do not agree with these
        terms, please do not use our services.
      </p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>
        By using the services provided on Bestabib, you acknowledge that
        you have read, understood, and agree to be bound by these Terms of
        Service, as well as any other policies or guidelines referenced in this
        document.
      </p>

      <h2>2. Description of Services</h2>
      <p>
        Bestabib provides a platform to search for doctors based on
        specialties, locations, and other criteria. Users can access the
        platform to find and connect with medical professionals. The platform
        also allows users to view doctor profiles, read reviews, and access
        contact information for healthcare providers.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>
        As a user, you agree to:
      </p>
      <ul>
        <li>Provide accurate, up-to-date, and complete information during the search.</li>
        <li>Use the platform only for lawful purposes.</li>
        <li>Not to engage in any activity that could harm the functionality or security of the platform.</li>
        <li>Not to impersonate any individual or entity or misrepresent your relationship with any party.</li>
      </ul>

      <h2>4. Account Registration</h2>
      <p>
        In order to use certain features of Bestabib, you may need to create an account. You agree to:
      </p>
      <ul>
        <li>Provide accurate and complete registration information.</li>
        <li>Keep your account information secure and confidential.</li>
        <li>Notify us immediately of any unauthorized access to your account.</li>
      </ul>

      <h2>5. Prohibited Activities</h2>
      <p>
        You are prohibited from:
      </p>
      <ul>
        <li>Using our platform for any illegal, harmful, or fraudulent activity.</li>
        <li>Engaging in data mining, scraping, or any other unauthorized data collection methods.</li>
        <li>Posting or transmitting harmful, defamatory, or offensive content.</li>
        <li>Attempting to gain unauthorized access to our platform or other systems and networks.</li>
      </ul>

      <h2>6. Intellectual Property</h2>
      <p>
        All content and materials available on Bestabib, including but not limited to text, graphics, logos, images, and software, are the intellectual property of Bestabib or its licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the platform solely for personal, non-commercial use.
      </p>

      <h2>7. Privacy and Data Collection</h2>
      <p>
        Your use of Bestabib is governed by our Privacy Policy, which outlines how we collect, use, and protect your data. Please review the Privacy Policy to understand how we handle your information.
      </p>

      <h2>8. Third-Party Links</h2>
      <p>
        Our platform may contain links to third-party websites that are not controlled or operated by Bestabib. We are not responsible for the content, privacy policies, or practices of these third-party sites. We encourage you to review their terms and privacy policies before using their services.
      </p>

      <h2>9. Disclaimers</h2>
      <p>
        Bestabib does not guarantee the accuracy, reliability, or completeness of the information provided by doctors or other healthcare professionals on the platform. We are not responsible for any actions, advice, or care provided by the doctors listed on our platform.
      </p>
      <p>
        The platform is provided "as is" without any warranties or representations, either express or implied. We do not guarantee that the platform will be error-free, secure, or continuously available.
      </p>

      <h2>10. Limitation of Liability</h2>
      <p>
        Bestabib shall not be held liable for any direct, indirect, incidental, special, or consequential damages arising out of your use of the platform or the inability to use it, even if we have been advised of the possibility of such damages. This includes any issues arising from doctor information, reviews, or any third-party content provided on the platform.
      </p>

      <h2>11. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless Bestabib, its affiliates, and employees from any claims, damages, losses, liabilities, or expenses arising out of your use of the platform, violation of these Terms of Service, or infringement of the rights of any third party.
      </p>

      <h2>12. Changes to Terms</h2>
      <p>
        Bestabib reserves the right to modify or update these Terms of Service at any time without prior notice. We encourage you to review this page periodically to stay informed about any changes. Continued use of the platform after any changes to the Terms of Service constitutes acceptance of those changes.
      </p>

      <h2>13. Governing Law</h2>
      <p>
        These Terms of Service are governed by and construed in accordance with the laws of Morocco, without regard to its conflict of law principles. Any disputes arising out of these Terms shall be subject to the exclusive jurisdiction of the courts in Morocco.
      </p>

      <h2>14. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, please contact us at:
        <br />
        Email: bestabib@gmail.com
      </p>
    </div>
  );
};

export default TermsOfService;
