import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { listBlogs } from '../../actions/blogActions';
import './BlogList.css';

interface Blog {
  id: number;
  title: string;
  content: string; // Contains HTML with potential <img> tags
  created_at: string;
}

interface BlogListState {
  loading: boolean;
  error: string | null;
  blogs: Blog[];
}

const BlogList: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [visiblePosts, setVisiblePosts] = useState(6);

  const { loading, error, blogs } = useSelector((state: { blogList: BlogListState }) => state.blogList);

  useEffect(() => {
    dispatch<any>(listBlogs());
  }, [dispatch]);

  const stripHtmlTags = (html: string) =>
    DOMPurify.sanitize(html, { USE_PROFILES: { html: false } }).slice(0, 70) + '...';

  const extractImageFromContent = (content: string): string | undefined => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(DOMPurify.sanitize(content), 'text/html');
    const img = doc.querySelector('img');
    return img?.getAttribute('src') || undefined;
  };

  const handleLoadMore = () => setVisiblePosts((prev) => prev + 3);

  const handleBlogClick = (blogId: number, title: string) => {
    const blogSlug = encodeURIComponent(title.replace(/\s+/g, '-').toLowerCase());
    const currentLanguage = i18n.language;
    navigate(`/${currentLanguage}/blog/${blogSlug}/${blogId}`);
    window.scrollTo(0, 0);
  };

  const generateStructuredData = (blog: Blog) => {
    const imageSrc = extractImageFromContent(blog.content);
    return {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      headline: blog.title,
      description: stripHtmlTags(blog.content),
      image: imageSrc || undefined,
      datePublished: blog.created_at,
    };
  };

  const isValidImage = (image: string | undefined): boolean => {
    if (!image) return false;
    return /^(https?:\/\/|data:image\/)/.test(image);
  };

  const visibleBlogs = Array.isArray(blogs) ? blogs.slice(0, visiblePosts) : [];
  const loadMoreLabel = t('Blog.LoadMore') || 'Load more';

  return (
    <main className="blogs-container">
      <section className="blog-list">
        <h1 className="blog-list-title">{t('Blog.LatestPosts')}</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div className="blog-list-items" role="list">
            {visibleBlogs.map((blog) => {
              const imageSrc = extractImageFromContent(blog.content);
              return (
                <article
                  key={blog.id}
                  className="blog-list-item"
                  role="listitem"
                  onClick={() => handleBlogClick(blog.id, blog.title)}
                  onKeyDown={(e) => e.key === 'Enter' && handleBlogClick(blog.id, blog.title)}
                  tabIndex={0}
                >
                  <script type="application/ld+json">
                    {JSON.stringify(generateStructuredData(blog))}
                  </script>
                  <div className="blog-content-wrapper">
                    {isValidImage(imageSrc) && (
                      <img
                        src={imageSrc}
                        srcSet={imageSrc?.startsWith('data:') ? undefined : `${imageSrc}?w=300 300w, ${imageSrc}?w=600 600w`}
                        sizes="(max-width: 600px) 300px, 600px"
                        alt={blog.title}
                        loading="lazy"
                        className="blog-image"
                        onError={(e) => {
                          e.currentTarget.style.display = 'none'; // Hide broken images
                        }}
                      />
                    )}
                    <div className="blog-text-content">
                      <h2 className="blog-title">{blog.title}</h2>
                      <p className="blog-summary">{stripHtmlTags(blog.content)}</p>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        )}
        {blogs.length > visiblePosts && (
          <div className="load-more-container">
            <button
              className="load-more-btn"
              aria-label={loadMoreLabel}
              onClick={handleLoadMore}
            >
              {loadMoreLabel}
            </button>
          </div>
        )}
      </section>
    </main>
  );
};

export default BlogList;