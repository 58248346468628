import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogById } from '../../actions/blogActions';
import './BlogDetails.css';
import DOMPurify from 'dompurify';

const BlogDetails: React.FC = () => {
  const { blogId } = useParams();
  const dispatch = useDispatch();

  const { blog, loading, error } = useSelector((state: any) => state.blogDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (blogId) {
      dispatch<any>(fetchBlogById(blogId));
    }
  }, [dispatch, blogId]);

  // Handle dynamic SEO updates
  useEffect(() => {
    if (!blog) return;

    document.title = `${blog.title} | My Blog`;

    const setMeta = (name: string, content: string) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    };

    setMeta('description', blog.excerpt || blog.content.slice(0, 150));
    setMeta('og:title', blog.title);
    setMeta('og:description', blog.excerpt || blog.content.slice(0, 150));
    setMeta('og:image', blog.image || 'https://www.bestabib.com/static/bestabibicon.png');
    setMeta('og:url', window.location.href);

    return () => {
      document.title = 'My Blog';
    };
  }, [blog]);
  

  // Generate structured data for SEO
  const generateStructuredData = () => {
    if (!blog) return null;

    return {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      headline: blog.title,
      description: blog.excerpt || blog.content.slice(0, 150),
      image: blog.image || '/default-image.jpg',
      datePublished: blog.created_at,
      dateModified: blog.updated_at,
    };
  };

  if (loading) return <p className="loading" aria-live="polite">Loading blog details...</p>;
  if (error) return <p className="error" aria-live="polite">Error: {error}</p>;
  if (!blog) return <p className="no-blog" aria-live="polite">No blog found.</p>;

  return (
    <article className="blog-details">
      {/* SEO Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(generateStructuredData())}
      </script>

      <header>
        <h1 className="blog-details-title">{blog.title}</h1>
        <time dateTime={blog.created_at} className="blog-details-date">
          {new Date(blog.created_at).toLocaleDateString()}
        </time>
      </header>

      <section
        className="blog-content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.content) }}
      />
    </article>
  );
};

export default BlogDetails;