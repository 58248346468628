import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./about.css";

const AboutScreen = () => {
  const { t } = useTranslation();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  const aboutEn = `
  <h1>About Us</h1>
  <p>Welcome to Bestabib, your trusted platform for searching and connecting with the best medical professionals. We understand the importance of finding the right doctor for your healthcare needs, and our mission is to make this process seamless, reliable, and efficient.</p>

  <h2>Our Vision</h2>
  <p>Our vision is to revolutionize healthcare accessibility by bridging the gap between patients and doctors. We aim to provide a user-friendly platform where individuals can easily search, compare, and connect with qualified healthcare professionals in their area.</p>

  <h2>What We Offer</h2>
  <ul>
      <li><strong>Comprehensive Doctor Directory:</strong> We provide an extensive database of doctors from various specialties, ensuring you find the right expert for your needs.</li>
      <li><strong>Advanced Search & Filters:</strong> Easily search for doctors based on specialty, location, ratings, and availability.</li>
      <li><strong>Verified Doctor Profiles:</strong> Each doctor profile includes qualifications, patient reviews, and consultation details to help you make informed decisions.</li>
      <li><strong>Appointment Booking:</strong> Schedule appointments effortlessly with just a few clicks.</li>
  </ul>

  <h2>Our Team</h2>
  <p>Bestabib is powered by a dedicated team of healthcare and technology professionals committed to improving the patient experience. We continuously work to enhance our platform, ensuring it remains a valuable resource for patients and medical professionals alike.</p>

  <h2>Contact Us</h2>
  <p>If you have any questions or feedback, feel free to reach out to us at <a href="mailto:bestabib@gmail.com">bestabib@gmail.com</a>.</p>

  <p>Thank you for choosing Bestabib – your partner in healthcare!</p>
  `;

  const aboutAr = `
  <h1>من نحن</h1>
  <p>مرحبًا بك في Bestabib، منصتك الموثوقة للبحث عن الأطباء والتواصل مع أفضل المتخصصين في الرعاية الصحية. نحن ندرك أهمية العثور على الطبيب المناسب لاحتياجاتك الصحية، ونسعى لجعل هذه العملية سلسة وموثوقة وفعالة.</p>

  <h2>رؤيتنا</h2>
  <p>رؤيتنا هي تحسين الوصول إلى الرعاية الصحية من خلال ربط المرضى بالأطباء بطريقة سهلة وفعالة. نوفر منصة سهلة الاستخدام تتيح للأفراد البحث عن الأطباء المؤهلين والتواصل معهم بكل سهولة.</p>

  <h2>ما نقدمه</h2>
  <ul>
    <li><strong>دليل شامل للأطباء:</strong> قاعدة بيانات واسعة تشمل أطباء من مختلف التخصصات لضمان العثور على الخبير المناسب.</li>
    <li><strong>البحث المتقدم والفلاتر:</strong> إمكانية البحث عن الأطباء حسب التخصص، الموقع، التقييمات، والتوافر.</li>
    <li><strong>ملفات أطباء موثوقة:</strong> تتضمن كل صفحة طبيب المؤهلات، آراء المرضى، وتفاصيل الاستشارة لمساعدتك في اتخاذ القرار الصحيح.</li>
    <li><strong>حجز المواعيد:</strong> يمكنك حجز مواعيد بسهولة تامة بنقرة واحدة فقط.</li>
  </ul>

  <h2>فريقنا</h2>
  <p>يتم تشغيل Bestabib بواسطة فريق متخصص في الرعاية الصحية والتكنولوجيا، يسعى دائمًا إلى تحسين تجربة المرضى وتقديم أفضل الخدمات.</p>

  <h2>اتصل بنا</h2>
  <p>إذا كان لديك أي استفسارات أو اقتراحات، لا تتردد في التواصل معنا عبر <a href="mailto:bestabib@gmail.com">bestabib@gmail.com</a>.</p>

  <p>شكرًا لاختيارك Bestabib – شريكك في الرعاية الصحية!</p>
  `;

  const aboutFr = `
  <h1>À Propos</h1>
  <p>Bienvenue sur Bestabib, votre plateforme de confiance pour rechercher et contacter les meilleurs professionnels de santé. Nous comprenons l'importance de trouver le bon médecin pour vos besoins médicaux, et notre mission est de rendre ce processus simple, fiable et efficace.</p>

  <h2>Notre Vision</h2>
  <p>Notre vision est d'améliorer l'accessibilité aux soins de santé en reliant les patients aux médecins. Nous offrons une plateforme conviviale où chacun peut rechercher et comparer facilement des professionnels de santé qualifiés.</p>

  <h2>Nos Services</h2>
  <ul>
    <li><strong>Annuaire complet des médecins :</strong> Une base de données large regroupant des médecins de diverses spécialités.</li>
    <li><strong>Recherche avancée et filtres :</strong> Trouvez facilement un médecin selon la spécialité, la localisation, les avis et la disponibilité.</li>
    <li><strong>Profils vérifiés :</strong> Chaque profil médical inclut les qualifications, les avis des patients et les détails de consultation.</li>
    <li><strong>Prise de rendez-vous :</strong> Réservez une consultation en quelques clics.</li>
  </ul>

  <h2>Notre Équipe</h2>
  <p>Bestabib est développé par une équipe passionnée qui s'engage à améliorer l'expérience des patients et à rendre les soins de santé plus accessibles.</p>

  <h2>Contactez-nous</h2>
  <p>Pour toute question, contactez-nous à <a href="mailto:bestabib@gmail.com">bestabib@gmail.com</a>.</p>

  <p>Merci de choisir Bestabib – votre partenaire santé !</p>
  `;

  return (
    <div className="about-screen" dir={dir}>
      <section
        className="about-section"
        dangerouslySetInnerHTML={{
          __html:
            i18n.language === "ar" ? aboutAr : i18n.language === "fr" ? aboutFr : aboutEn,
        }}
      />
    </div>
  );
};

export default AboutScreen;
