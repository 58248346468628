import React, { useEffect, useState } from 'react';
import './BlogSection.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBlog,
  deleteBlog,
  getBlogs,
  updateBlog,
} from '../../actions/userActions';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

// TypeScript Interfaces
interface Blog {
  id: number;
  title: string;
  content: string;
  author: number;
}

interface UserInfo {
  id: number;
}

interface State {
  userLogin: { userInfo: UserInfo | null };
  userBlogs: { blogs: Blog[] };
}

interface BlogPayload {
  author: string;
  title: string;
  content: string;
}

const BlogSection = () => {
  const { t } = useTranslation();
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingBlogId, setEditingBlogId] = useState<number | null>(null);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const userLogin = useSelector((state: State) => state.userLogin);
  const { userInfo } = userLogin;
  const blogList = useSelector((state: State) => state.userBlogs.blogs);

  useEffect(() => {
    if (userInfo?.id) {
      dispatch<any>(getBlogs(userInfo.id));
    }
  }, [dispatch, userInfo?.id]);

  // Format content for SEO, including image enhancements
  // const formatBlogContentForSEO = (content: string, title: string): string => {
  //   let formattedContent = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

  //   // Enhance images with SEO properties
  //   formattedContent = formattedContent.replace(
  //     /<img([^>]*?)src=["'](.*?)["']([^>]*?)>/gi,
  //     (match, preAttrs, src, postAttrs) => {
  //       // Extract existing attributes
  //       const hasAlt = /alt=["'](.*?)["']/i.exec(match);
  //       const altText = hasAlt ? hasAlt[1] : `${title} - image`; // Use existing alt or generate one
  //       const hasWidth = /width=["'](.*?)["']/i.test(match);
  //       const hasHeight = /height=["'](.*?)["']/i.test(match);

  //       // Build new img tag with SEO properties
  //       return `<img${preAttrs} src="${src}" alt="${altText}" decoding="async"${
  //         !hasWidth ? ' width="auto"' : ''
  //       }${!hasHeight ? ' height="auto"' : ''}${postAttrs}>`;
  //     }
  //   );

  //   // Ensure heading structure
  //   if (!/<h[1-6]/i.test(formattedContent)) {
  //     formattedContent = `<h2>${title}</h2>${formattedContent}`;
  //   }

  //   // Normalize paragraph spacing
  //   formattedContent = formattedContent.replace(/<\/p>\s*<p>/gi, '</p><p>');
  //   return formattedContent;
  // };
  // Format content for SEO, including image enhancements
const formatBlogContentForSEO = (content: string, title: string): string => {
  let formattedContent = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

  // Encode the title for safe use in HTML attributes
  const safeTitle = DOMPurify.sanitize(title).replace(/["']/g, ''); // Remove problematic quotes
  const encodedTitle = encodeURIComponent(safeTitle); // URL-safe title
  
  // Enhance images with SEO properties
  formattedContent = formattedContent.replace(
    /<img([^>]*?)src=["'](.*?)["']([^>]*?)>/gi,
    (match, preAttrs, src, postAttrs) => {
      // Extract existing attributes
      const hasAlt = /alt=["'](.*?)["']/i.exec(match);
      const altText = hasAlt ? hasAlt[1] : `${safeTitle} - image`; // Use existing alt or generate one
      const hasWidth = /width=["'](.*?)["']/i.test(match);
      const hasHeight = /height=["'](.*?)["']/i.test(match);

      // Build new img tag with SEO properties
      return `<img${preAttrs} src="${src}" alt="${altText}" decoding="async"${
        !hasWidth ? ' width="auto"' : ''
      }${!hasHeight ? ' height="auto"' : ''}${postAttrs}>`;
    }
  );

  // Ensure heading structure
  if (!/<h[1-6]/i.test(formattedContent)) {
    formattedContent = `<h2>${safeTitle}</h2>${formattedContent}`;
  }

  // Normalize paragraph spacing
  formattedContent = formattedContent.replace(/<\/p>\s*<p>/gi, '</p><p>');

  return formattedContent;
};


  const handleSaveBlog = (e: React.FormEvent) => {
    e.preventDefault();

    if (!blogTitle || !blogContent) {
      alert(t('Blog.RequiredFields') || 'Both title and content are required!');
      return;
    }

    if (!userInfo?.id) {
      alert(t('Blog.UserNotLoggedIn') || 'You must be logged in to add a blog!');
      return;
    }

    const formattedContent = formatBlogContentForSEO(blogContent, blogTitle);
    const blogData: BlogPayload = {
      author: userInfo.id.toString(),
      title: blogTitle,
      content: formattedContent,
    };

    if (isEditing && editingBlogId !== null) {
      dispatch<any>(updateBlog(editingBlogId, blogData));
    } else {
      dispatch<any>(addBlog(blogData));
    }

    setBlogTitle('');
    setBlogContent('');
    setIsEditing(false);
    setEditingBlogId(null);
    setShowForm(false);
  };

  const handleDeleteBlog = (id: number) => {
    if (window.confirm(t('Blog.ConfirmDelete') || 'Are you sure you want to delete this blog?')) {
      dispatch<any>(deleteBlog(id));
    }
  };

  const handleEditBlog = (id: number, title: string, content: string) => {
    setBlogTitle(title);
    setBlogContent(content);
    setIsEditing(true);
    setEditingBlogId(id);
    setShowForm(true);
  };

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
  };

  return (
    <div className="blog-section">
      <h2>{t('Blog.Blog_Management')}</h2>

      <button onClick={() => setShowForm(!showForm)}>
        {showForm ? t('Button.Cancel') : t('Button.Add_Blog')}
      </button>

      {showForm && (
        <form onSubmit={handleSaveBlog}>
          <div>
            <label>{t('Blog.Title')}</label>
            <br />
            <input
              type="text"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
              placeholder={t('Blog.EnterBlogTitle') || 'Enter blog title'}
            />
          </div>
          <div>
            <label>{t('Blog.Content')}</label>
            <ReactQuill
              value={blogContent}
              onChange={setBlogContent}
              modules={quillModules}
              theme="snow"
            />
          </div>
          <button type="submit">
            {isEditing ? t('Button.Update') : t('Button.Add')}
          </button>
        </form>
      )}

      <div className="blog-list">
        {blogList && blogList.length > 0 ? (
          blogList.map((blog: Blog) => (
            <div key={blog.id} className="blog-item">
              <h3>{blog.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: blog.content.slice(0, 100) + '...' }} />
              <div className="blog-actions">
                <button onClick={() => handleEditBlog(blog.id, blog.title, blog.content)}>
                  {t('Button.Update')}
                </button>
                <button onClick={() => handleDeleteBlog(blog.id)}>
                  {t('Button.Delete')}
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>{t('Blog.No_blogs')}</p>
        )}
      </div>
    </div>
  );
};

export default BlogSection;