import React from "react";
import "./PrivacyScreen.css";

const PrivacyScreen = () => {
  return (
    <div dir="ltr" className="PrivacyScreen">
      <h1>Privacy Policy for Bestabib</h1>
      <p>
        At Bestabib, accessible from https://www.Bestabib.com, one of our
        main priorities is the privacy of our visitors. This Privacy Policy
        document contains types of information that is collected and recorded by
        Bestabib and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in Bestabib.com. This policy is not applicable to
        any information collected offline or via channels other than this
        website.
      </p>
      <h2>Consent</h2>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We may collect personal information such as your name, email address,
        phone number, and location to help you find doctors that match your needs.
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p>
        If you contact us directly, we may receive additional information about
        you such as the contents of the message and/or attachments you may send
        us, and any other information you may choose to provide.
      </p>
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our platform</li>
        <li>Improve, personalize, and expand our platform</li>
        <li>Understand and analyze how you use our platform</li>
        <li>Develop new products, services, and features</li>
        <li>Communicate with you, including for customer support and updates</li>
        <li>Send you relevant information about healthcare services</li>
      </ul>
      <h2>Log Files</h2>
      <p>
        Bestabib follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks.
      </p>
      <h2>Cookies and Web Beacons</h2>
      <p>
        Like any other website, Bestabib uses cookies. These cookies are
        used to store information including visitors' preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and other information.
      </p>
      <h2>Third Party Services</h2>
      <p>
        We may use third-party service providers to assist us in the operation of
        our platform and to analyze how you use our services. These third parties
        have access to your personal information only to perform these tasks on
        our behalf and are obligated not to disclose or use it for any other
        purpose.
      </p>
      <h2>Data Security</h2>
      <p>
        We take the security of your personal information seriously. We implement
        reasonable administrative, technical, and physical safeguards to protect
        the personal data we collect.
      </p>
      <h2>Your Data Protection Rights</h2>
      <p>
        Depending on your location, you may have the right to request access to
        the personal data we hold about you, request that we correct or delete
        any inaccurate data, or object to the processing of your data. To exercise
        any of these rights, please contact us.
      </p>
      <h2>Children's Information</h2>
      <p>
        Bestabib does not knowingly collect any personal information from
        children under the age of 13. If you believe your child has provided
        personal information on our platform, please contact us immediately so
        that we can take the necessary steps to remove such data.
      </p>
      <p>
        For more information, feel free to reach out to us with any concerns.
      </p>
    </div>
  );
};

export default PrivacyScreen;
